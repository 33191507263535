
import {defineComponent, reactive, ref, toRefs} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: "contact",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const submitButton = ref<HTMLButtonElement | null>(null);

    const registration = Yup.object().shape({
      first_name: Yup.string().required(`${t('is_required')}`).label(`${t('first_name')}`),
      last_name: Yup.string().required(`${t('is_required')}`).label(`${t('last_name')}`),
      email: Yup.string().min(4).required(`${t('is_required')}`).email().label(`${t('email')}`),
      phone: Yup.string().min(8).required(`${t('is_required')}`).label(`${t('phone')}`),
      message: Yup.string().required(`${t('is_required')}`).min(8).label(`${t('message')}`),
    });

    const onSubmitContact = (values) => {

      // eslint-disable-next-line
      submitButton.value!.disabled = true;

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      // Dummy delay
      setTimeout(() => {
        // Send login request
        store
            .dispatch(Actions.CONTACT, values)
            .then(() => {
              Swal.fire({
                text: "Message Sent Successfully!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Go to page after successfully login
                router.push({ name: "dashboard" });
              });
            })
            .catch(() => {
              const error = Object.values(store.getters.getErrors.error);
              Swal.fire({
                html: error.map(item => `<p>${item}</p>`)
                    .join(''),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });

        submitButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      }, 2000);
    };

    return {
      registration,
      onSubmitContact,
      submitButton,
    };
  },
});
